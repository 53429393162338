var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"sm"}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols":"3","label-cols-lg":"3","label":"1.Đợt xét tuyển:","label-for":"admissionRoundSelected"}},[_c('v-select',{attrs:{"options":_vm.admissionRounds,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"1.Đợt xét tuyển"},model:{value:(_vm.admissionRoundSelected),callback:function ($$v) {_vm.admissionRoundSelected=$$v},expression:"admissionRoundSelected"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols":"3","label-cols-lg":"3","label":"2.Phương thức xét tuyển:","label-for":"admissionFormSelected"}},[_c('v-select',{attrs:{"options":_vm.dataListAdmissionForm,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"2.Phương thức xét tuyển"},model:{value:(_vm.admissionFormSelected),callback:function ($$v) {_vm.admissionFormSelected=$$v},expression:"admissionFormSelected"}})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"7"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols":"2","label-cols-lg":"2","label":"3.Ngành xét tuyển:","label-for":"admissionMajorSelected"}},[_c('v-select',{attrs:{"options":_vm.dataListMajorByAdmissionForm,"reduce":function (option) { return option.value; },"placeholder":"3.Ngành xét tuyển:"},model:{value:(_vm.admissionMajorSelected),callback:function ($$v) {_vm.admissionMajorSelected=$$v},expression:"admissionMajorSelected"}})],1)],1)],1)],1)],1),_c('validation-observer',{ref:"studentExaminationPointFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticStyle:{"margin-right":"5px"},attrs:{"variant":"success"},on:{"click":_vm.onSelectStudentExaminationRoom}},[_c('span',{staticClass:"text-nowrap text-right"},[_vm._v("Xem danh sách")])]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.checkedShowSave),expression:"!checkedShowSave"},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.onSave(_vm.codeByAdmissionMajor)}}},[_c('span',{staticClass:"text-nowrap text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Lưu lại ")],1)])],1)])],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-good-table',{staticClass:"my-table",attrs:{"mode":"remote","columns":_vm.visibleColumns,"rows":_vm.codeByAdmissionMajor,"pagination-options":_vm.paginationOptions,"line-numbers":true,"style-class":"my-table vgt-table striped bordered"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(['standardScore', 'acceptedCountTemp'].includes(props.column.field))?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!(props.row.admissionFormTypeCode === 'TUYENTHANG')),expression:"!(props.row.admissionFormTypeCode === 'TUYENTHANG')"}]},[_c('validation-provider',{attrs:{"rules":_vm.getValidationRules(props.column.field),"name":_vm.getFieldName(props.column.field)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"tabindex":props.row.originalIndex + 1},on:{"change":function($event){return _vm.changeCell(
                              props.row[props.column.field],
                              props.column.field,
                              props.row.originalIndex
                            )}},model:{value:(props.row[props.column.field]),callback:function ($$v) {_vm.$set(props.row, props.column.field, $$v)},expression:"props.row[props.column.field]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}],null,true)},[_c('div',{staticStyle:{"text-align":"center","font-weight":"bold"},attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Không có bản ghi nào ! ")])])],1)],1)]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }